<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" xl="6">
          <v-card class="elevation-12">
            <v-card-text style="max-height: 100%" scrollable class="px-6 overflow-y-auto">
              <div class="text-center">
                <img src="/images/prodriveacademy-logo.png" />
              </div>
              <h1 class="mb-4">Prodrive Academy e-learning privacy policy</h1>
              <p>
                  Prodrive Academy respecteert de privacy van alle gebruikers van haar website en diensten,
                  en draagt er zorg voor dat de persoonlijke gegevens die u ons verschaft vertrouwelijk worden behandeld.
                  Wij gebruiken uw gegevens om de e-learning uitslagen persoonlijk aan u te kunnen binden.
                  Voor het overige zullen wij uw gegevens uitsluitend gebruiken met uw toestemming.
                  Gegevens worden opgeslagen op een beveiligde server.
              </p>
              <br>

              <h2 class="mb-2">Prodrive Academy gebruikt de verzamelde persoonsgegevens om haar gebruikers de volgende diensten te kunnen voorzien:</h2>
              <ul class="mb-3">
                  <li>De e-learning resultaten worden gekoppeld aan u als persoon zodat er indien nodig gecontrollerd kan worden of u de e-learning heeft voltooid en behaald.</li>
                  <li>Tijdens het opnemen van de kennis en het doorlopen van de toetsen wordt er een persoonlijke sessie bijgehouden zodat de server weet wie de toets aan het maken is.</li>
              </ul>
              <br>

              <h2 class="mb-2">Uw persoonlijke gegevens zijn veilig bij Prodrive Academy</h2>
              <p>
                  Wij waarderen het vertrouwen dat u in Prodrive Academy stelt en wij zullen daarom zeer zorgvuldig met uw gegevens omgaan.
                  Wanneer u onze website bezoekt en inlogt, wordt er gebruik gemaakt van de modernste techniek om uw gegevens te beschermen.
              </p>
              <br>

              <h2 class="mb-2">Bewaartijd</h2>
              <p>
                  Wij verdelen de gegevens in twee categorieën. De eerste categorie gegevens bevatten uw contactgegevens en de behaalde certificaten.
                  Deze gegevens blijven bewaard voor een onbepaalde tijd, tenzij u een verzoek indient om deze gegevens te verwijderen.
                  Via deze methode kunnen wij in de toekomst onderscheid maken tussen bestuurders met en zonder certificaat.
                  De tweede categorie gegevens zijn sessie gegevens. Dit betreffen de individuele antwoorden van de toets(en) en overige functioneel gerelateerde sessie gegevens.
                  Deze tweede categorie zal een bewaartijd hebben van uiterlijk 1 jaar.
              </p>
              <br>

              <h2 class="mb-2">Cookies</h2>
              <p>
                  Cookies zijn kleine stukjes informatie die door uw browser worden opgeslagen op uw computer, tablet of mobiele telefoon.
                  Prodrive Academy gebruikt functionele cookies om de website te laten functioneren.
                  Er worden geen cookies gebruikt voor reclame doeleinden.
                  Externe cookies zijn noodzakelijk voor Google Analytics (zie volgende paragraaf).
              </p>
              <br>

              <h2 class="mb-2">Google Analytics</h2>
              <p>
                  Deze website maakt gebruik van Google Analytics, een webanalyse-service die wordt aangeboden door Google Inc. ("Google"). Google Analytics maakt gebruik van "cookies" (tekstbestandjes die op Uw computer worden geplaatst) om de website te helpen analyseren hoe gebruikers de site gebruiken. De door het cookie gegenereerde informatie over uw gebruik van de website wordt overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten. Google gebruikt deze informatie om bij te houden hoe u de website gebruikt, rapporten over de website-activiteit op te stellen voor website-exploitanten en andere diensten aan te bieden met betrekking tot website-activiteit en internetgebruik.
              </p>
              <p>
                  Google mag deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover deze derden de informatie namens Google verwerken. U kunt het gebruik van cookies weigeren door in Uw browser de daarvoor geëigende instellingen te kiezen. Wij wijzen u er echter op dat u in dat geval wellicht niet alle mogelijkheden van deze website kunt benutten.
              </p>
              <p>
                  Door gebruik te maken van deze website geeft u toestemming voor het verwerken van de informatie door Google op de wijze en voor de doeleinden zoals hiervoor omschreven.
              </p>
              <p>
                  Deze analytics zijn gebasseerd op anonieme gebruikersstatistieken en komen niet in verbinding met u als persoon (naw, etc).
              </p>
              <br>

              <h2 class="mb-2">Cookies van derde partijen</h2>
              <p>
                  Voor de cookies die derde partijen op onze website zijn geplaatst, verwijzen wij naar de verklaringen,
                  die deze partijen op hun eigen websites daarover geven. Deze verklaringen kunnen regelmatig wijzigen.
                  "Prodrive Academy" heeft hier geen invloed op. De namen van deze partijen en het doel kunt u hieronder terugvinden.
                  <br><br>
                  Google Analytics: Anonieme informatie over surfgedrag verzamelen<br>
              </p>
              <br>

              <h2 class="mb-2">Contact</h2>
              <p>
                  Indien u nog vragen heeft over de privacy policy van Prodrive Academy, dan kunt u onze contactgegevens vinden op de <a href="https://www.prodriveacademy.nl/contact" target="_blank">contact pagina</a>.
              </p>
              <br>
              <p>
                  <router-link :to="{ name: 'chapters' }">Terug</router-link>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
};
</script>

<style scoped>
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 18px;
    }
    img {
        padding: 24px 0 30px;
    }
</style>
