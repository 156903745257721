<template>
    <v-app>
        <v-app-bar app color="transparent" flat>
            <v-spacer></v-spacer>

            <v-menu v-if="loggedInUser" bottom left offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text depressed v-on="on">
                        <v-icon left>mdi-account-circle</v-icon>
                        {{loggedInUser.name}}
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item :to="{ name: 'privacyPolicy' }">
                        <v-list-item-title>Privacy Policy</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-on:click="isShowingMyAccountModal = true">
                        <v-list-item-title>Mijn account</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-title>Uitloggen</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </v-app-bar>

        <router-view></router-view>

        <v-footer color="transparent" class="mb-4 mr-4">
            <v-spacer></v-spacer>
            <div class="powered-by">
                <span class="white--text">Powered by</span>
                <a href="https://vvcr-prodrive.nl/" target="_blank" title="Powered by VVCR Prodrive">
                    <v-img width="160" src="/images/prodriveacademy-logo-white.png" contain />
                </a>
            </div>
        </v-footer>

        <v-dialog v-model="isShowingMyAccountModal" max-width="400">
            <v-card>
                <v-card-title class="headline">Mijn account</v-card-title>
                <v-card-text>
                    <template v-if="!isChangingName && !isChangingPassword">
                        <h3>Naam</h3>
                        <p class="fs-18" v-if="loggedInUser">
                            {{loggedInUser.name}}
                            <v-btn color="primary" text @click="onChangeNameClick">wijzigen</v-btn>
                        </p>

                        <h3>Wachtwoord</h3>
                        <p class="fs-18">
                            &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                            <v-btn color="primary" text @click="onChangePasswordClick">wijzigen</v-btn>
                        </p>
                    </template>
                    <template v-else>
                        <template v-if="isChangingName">
                            <v-text-field v-model="newName" ref="formName" type="text" prepend-icon="mdi-account-outline" label="Voor en achternaam" :disabled="isSubmitting" :rules="nameRules" :validate-on-blur="!isFormNameValid" required></v-text-field>
                        </template>
                        <template v-if="isChangingPassword">
                            <v-text-field v-model="newPassword" ref="formPassword" type="password" prepend-icon="mdi-fingerprint" label="Nieuw wachtwoord" :disabled="isSubmitting" :rules="passwordRules" :validate-on-blur="!isFormPasswordValid" required></v-text-field>
                            <label>Het wachtwoord moet minimaal 8 tekens lang zijn en een letter en een cijfer bevatten.</label>
                        </template>
                    </template>
                </v-card-text>

                <v-card-actions v-if="!isChangingName && !isChangingPassword">
                    <v-btn color="primary" block text @click="isShowingMyAccountModal = false">Sluiten</v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                    <v-btn color="primary" text @click="onCancelChangeAccount">Annuleren</v-btn>
                    <v-spacer/>
                    <v-btn color="primary" @click="onChangeClick" :disabled="!(isFormNameValid || isFormPasswordValid) || isSubmitting">Wijzigen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import AuthenticationManager from './services/AuthenticationManager';
import AxiosApi from './services/AxiosApi';

export default {
    name: 'App',

    data: () => ({
        isShowingMyAccountModal: false,
        isChangingName: false,
        isChangingPassword: false,
        isSubmitting: false,
        newName: '',
        newPassword: '',
        isFormNameValid: false,
        isFormPasswordValid: false,
        nameRules: [
            (value) => {
                return !!(value && 5 < value.length && value.indexOf(' ')) || 'Voor + achternaam';
            },
        ],
        passwordRules: [
            (value) => {
                return /^(?=.*[a-z])(?=.*)(?=.*\d).{8,100}$/.test(value) || 'Minimaal 8 tekens en moet een letter en een cijfer bevatten';
            },
        ],
    }),

    watch: {
        newName () {
            this.$nextTick(() => {
                if (!this.$refs.hasOwnProperty('formName') || !this.isChangingName) {
                    this.isFormNameValid = false;
                } else {
                    this.isFormNameValid = this.$refs.formName.validate();
                }
            });
        },
        newPassword () {
            this.$nextTick(() => {
                if (!this.$refs.hasOwnProperty('formPassword') || !this.isChangingPassword) {
                    this.isFormPasswordValid = false;
                } else {
                    this.isFormPasswordValid = this.$refs.formPassword.validate();
                }
            });
        }
    },

    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        },
    },

    methods: {
        logout() {
            AuthenticationManager.invalidateSession();
            this.$router.push({ name: 'login' });
        },
        onChangeClick() {
            if (this.isSubmitting) {
                return;
            }

            this.isSubmitting = true;

            if (this.isChangingName) {
                // Save name
                AxiosApi.request({
                    url: '/api/update-name',
                    method: 'post',
                    data: {
                        name: this.newName,
                    }
                }).then(() => {
                    // ok
                    this.loggedInUser.name = this.newName;
                    this.newName = '';
                    this.isChangingName = false;
                }).finally(() => {
                    this.isSubmitting = false;
                });
            } else if (this.isChangingPassword) {
                // Save password
                AxiosApi.request({
                    url: '/api/update-password',
                    method: 'post',
                    data: {
                        password: this.newPassword,
                    }
                }).then(() => {
                    // ok
                    this.newPassword = '';
                    this.isChangingPassword = false;
                }).finally(() => {
                    this.isSubmitting = false;
                });
            }
        },
        onChangeNameClick() {
            this.newName = this.loggedInUser.name;
            this.isChangingName = true;
        },
        onChangePasswordClick() {
            this.newPassword = '';
            this.isChangingPassword = true;
        },
        onCancelChangeAccount() {
            this.isChangingName = false;
            this.isChangingPassword = false;
            this.newName = '';
            this.newPassword = '';
        }
    },
};
</script>

<style lang="scss">
    @import 'styles/app';

    html, body {
        height: 100%;
        overflow: hidden !important;
    }
    @media screen and (min-width: 960px) {
        main.v-content {
            padding-top: 0 !important;
        }
    }
    .container.fill-height > .row {
        max-width: 100% !important;
        margin-left: 0;
        margin-right: 0;
    }
    body > .v-application {
        overflow-y: scroll;
        height: 100%;
        background: url('/images/background-blurred.jpg') no-repeat center center !important;
        background-size: cover !important;
    }
    .v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
        line-height: 3 !important;
    }
    .well {
        padding: 12px;
        border: 1px solid #e4e4e4;
        border-radius: 3px;
        background: #fbfbfb;
        margin: 12px 0 10px;
        font-size: 15px;
        animation: fadein .5s;
        -moz-animation: fadein .5s; /* Firefox */
        -webkit-animation: fadein .5s; /* Safari and Chrome */
        -o-animation: fadein .5s; /* Opera */
    }
    .well-info {
        background: #e3e8ee;
    }
    .well i.v-icon {
        margin-top: -1px;
    }
    p.icon-left {
        position: relative;
        padding-left: 72px;
    }
    p.icon-left i.v-icon {
        font-size: 36px;
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -18px;
    }

    .vp-outro-wrapper {
        display: none !important;
    }

    .underline {
        text-decoration: underline;
    }

    .pr-20 {
        padding-right: 20px;
    }
    .mt--20 {
        margin-top: -20px;
    }
    .fs-18 {
        font-size: 18px;
    }

    .powered-by {
        font-size: 12px;
        opacity: 0.9;
    }
    .powered-by span {
        display: inline-block;
        padding-bottom: 2px;
    }

    .v-input--selection-controls .v-input__control {
        width: 100%;
    }

    @keyframes fadein {
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-moz-keyframes fadein { /* Firefox */
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-webkit-keyframes fadein { /* Safari and Chrome */
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-o-keyframes fadein { /* Opera */
        from {
            opacity:0;
        }
        to {
            opacity: 1;
        }
    }
</style>
