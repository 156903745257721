<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="6" xl="4">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <v-card-text class="px-6">
                            <h1>Inloggen</h1>
                            <p class="mt-2">
                                Inloggen is mogelijk nadat je <router-link :to="{ name: 'registration' }">geregistreerd</router-link> bent.
                            </p>

                            <v-form ref="form" v-model="isFormValid" v-on:submit="onFormSubmit" class="mb-2">
                                <p class="well well-info mb-4" v-if="formError">{{formError}}</p>

                                <v-text-field v-model="email" ref="formEmail" type="email" prepend-icon="mdi-email-outline" label="Emailadres" :disabled="isSubmittingForm" :rules="emailRules" :validate-on-blur="!isFormEmailValid" required></v-text-field>
                                <v-text-field v-model="password" type="password" prepend-icon="mdi-fingerprint" label="Wachtwoord" :disabled="isSubmittingForm" validate-on-blur required></v-text-field>

                                <div class="mt-1">
                                    <v-btn type="submit" depressed color="primary" large block :disabled="!isFormValid || !password || isSubmittingForm || isFormSubmitted">Inloggen</v-btn>
                                    <div class="mt-4 mb-1 text-center">
                                        <a v-on:click="isShowingForgotPasswordDialog = true" href="javascript:void(0);">Wachtwoord vergeten</a>
                                        <span class="divider">|</span>
                                        <router-link :to="{ name: 'registration' }">Registreren</router-link>
                                    </div>
                                </div>
                            </v-form>
                        </v-card-text>

                        <v-progress-linear v-if="isSubmittingForm" indeterminate color="primary"></v-progress-linear>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="isShowingForgotPasswordDialog" max-width="360">
            <v-card>
                <v-card-title class="headline">Wachtwoord vergeten?</v-card-title>
                <v-card-text>
                    Vul je emailadres in om je wachtwoord te kunnen resetten. Je ontvangt een nieuw wachtwoord per mail.

                    <v-container>
                        <v-row>
                            <v-text-field v-if="!isPasswordForgetFormSubmitted" label="Email adres" v-model="email" required :disabled="isSubmittingPasswordForgetForm"></v-text-field>
                            <p v-else class="well well-info">Het nieuwe wachtwoord wordt binnen enkele ogenblikken verstuurd naar het emailadres <strong>{{email}}</strong></p>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!isPasswordForgetFormSubmitted">
                    <v-btn text @click="isShowingForgotPasswordDialog = false" :disabled="isSubmittingPasswordForgetForm">Annuleren</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="onPasswordResetClick()" :disabled="isSubmittingPasswordForgetForm">Wachtwoord resetten</v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                    <v-btn color="primary" text @click="isShowingForgotPasswordDialog = false" block>Sluiten</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';
import AuthenticationManager from './services/AuthenticationManager';
import AxiosApi from './services/AxiosApi';
import UserService from './services/UserService';
import TestService from './services/TestService';

export default {
    components: {
        HeaderImage,
    },
    computed: {
        organization() {
            return this.$store.state.organization;
        },
    },
    watch: {
        email () {
            this.isFormEmailValid = this.$refs.formEmail.validate();
        },
    },
    data: () => ({
        name: null,
        email: null,
        password: null,
        isFormValid: false,
        isSubmittingForm: false,
        isFormSubmitted: false,
        isFormEmailValid: false,
        formError: null,
        isShowingForgotPasswordDialog: false,
        isSubmittingPasswordForgetForm: false,
        isPasswordForgetFormSubmitted: false,

        emailRules: [
            (value) => {
                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value) || 'Geen geldig email adres';
            },
        ],
    }),

    created () {
        if (this.$route.query.hasOwnProperty('email')) {
            this.email = this.$route.query.email;
        }
    },

    destroyed () {
        this.email = null;
        this.password = null;
    },

    methods: {
        onFormSubmit (e) {
            if (e) {
                e.preventDefault();
            }

            this.formError = null;
            this.$refs.form.validate();

            if (!this.isFormValid) {
                return;
            }

            this.isFormSubmitted = false;
            this.isSubmittingForm = true;

            UserService.unloadUser();
            TestService.unloadTest();

            AuthenticationManager.authenticate(this.email, this.password).then((response) => {
                this.isFormSubmitted = true;
                AuthenticationManager._setOAuth(response.data);
                this.$router.push({ name: 'explanation' });
            }).catch((error) => {
                this.isFormSubmitted = false;
                switch (error.response.data.error) {
                    case 'invalid_grant':
                        if ('User is not activated.' === error.response.data.error_description) {
                            this.formError = 'Het emailadres is nog niet gevalideerd.';
                        } else if ('Invalid username and password combination.' === error.response.data.error_description) {
                            this.formError = 'Het opgegeven wachtwoord is onjuist.';
                        }
                        break;
                    default:
                        this.formError = 'Er is een onbekende fout opgetreden';
                }
            }).finally(() => {
                this.isSubmittingForm = false;
            });

            return false;
        },

        onPasswordResetClick () {
            this.isSubmittingPasswordForgetForm = true;

            AxiosApi.request({
                url: 'reset-password',
                method: 'post',
                data: {
                    email: this.email,
                }
            }).then(() => {
                this.isPasswordForgetFormSubmitted = true;
            }).finally(() => {
                this.isPasswordForgetFormSubmitted = true;
                this.isSubmittingPasswordForgetForm = false;
            });
        }
    }
};
</script>

<style scoped>
    span.divider {
        padding: 0 8px;
        display: inline-block;
    }
</style>