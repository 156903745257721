import AxiosApi from '@/services/AxiosApi';
import UserService from '@/services/UserService';
import store from '../store';

const AuthenticationManager = {
    localStorageKey: 'oauth-session',
    clientId: '15_5aodlnfze0sgo0ogow0gw40o8gckss80oc48ksc0wwgcs0k080',
    clientSecret: '1bz1ez3qlbvows4sw4g0ow0s0s4880888w40gcskwwkg8oos4k',

    async getToken () {
        let oauth;

        // Check if OAuth object exists in store
        oauth = store.getters.oauth;

        if (!oauth) {
            // Check if OAuth object exists in localStorage
            oauth = this._getOAuthFromLocalStore();

            if (oauth) {
                this._setOAuth(oauth);
            }
        }

        if (!oauth) {
            // Not authenticated
            return new Promise((resolve) => {
                resolve(false);
            });
        }

        // Check if oauth is expired
        if (Math.round(Date.now() / 1000) > oauth.expires_at) {
            // Is expired, refresh token
            this._setOAuth(null);

            return AxiosApi.request({
                url: '/oauth/v2/token',
                method: 'post',
                data: {
                    grant_type: 'refresh_token',
                    refresh_token: oauth.refresh_token,
                    client_id: this.clientId,
                    client_secret: this.clientSecret,
                }
            }).then((result) => {
                this._setOAuth(result.data);

                return new Promise((resolve) => {
                    resolve(result.data.access_token);
                });
            }).catch(() => {
                this._setOAuth(null);

                return new Promise((resolve) => {
                    resolve(false);
                });
            });
        } else {
            return new Promise((resolve) => {
                resolve(oauth.access_token);
            });
        }
    },

    async isAuthenticated () {
        return this.getToken().then((result) => {
            let isAuthenticated = false;

            if (result) {
                isAuthenticated = true;
            }

            return new Promise((resolve) => {
                resolve(isAuthenticated);
            });
        });
    },

    invalidateSession () {
        this._setOAuth(null);
        UserService.unloadUser();
    },

    async authenticate (email, pass) {
        return AxiosApi.request({
            url: '/oauth/v2/token',
            method: 'post',
            data: {
                grant_type: 'password',
                username: email,
                password: pass,
                client_id: this.clientId,
                client_secret: this.clientSecret,
            }
        });
    },

    _refreshToken () {
        return true;
    },

    _getOAuth() {

    },

    _setOAuth(oauth) {
        if (oauth && oauth.hasOwnProperty('expires_in') && oauth.hasOwnProperty('access_token')) {
            // Token is valid
            if (!oauth.hasOwnProperty('expires_at')) {
                // Token is new, so set expires_at
                oauth.expires_at = Math.round(Date.now() / 1000) + oauth.expires_in;
            }

            AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${oauth.access_token}`;
            localStorage.setItem('oauth-session', JSON.stringify(oauth));
        } else {
            AxiosApi.defaults.headers.common['Authorization'] = '';
            localStorage.setItem('oauth-session', null);
        }

        store.dispatch('setOAuth', oauth);
    },

    _getOAuthFromLocalStore() {
        let hash = localStorage.getItem(this.localStorageKey);

        // Check if localStorage contains oauth
        if (hash && 'null' !== hash && undefined !== hash && '' !== hash)
        {
            return JSON.parse(hash);
        }

        return false;
    },

    register(data) {
        data.client = this.clientId;

        return AxiosApi.request({
            url: '/api/users',
            method: 'post',
            data: data,
        });
    },
};

export default AuthenticationManager
