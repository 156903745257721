<template>
    <p class="well mt-4">
        <span v-if="0 === questionsCount">
            Deze module is nu afgerond.

            <span v-if="chapter.test.nextChapter">
                Je kunt nu doorgaan met de volgende module "{{chapter.test.nextChapter.title}}".
            </span>
        </span>
        <span v-else>
            <span>Deze module is nu afgerond. Je hebt {{correctQuestionsPercentage}}% goed beantwoordt.</span>
            <span v-if="1 === wrongQuestionsCount">Bekijk de fout beantwoordde vraag nog even goed en ga dan verder.</span>
            <span v-if="1 < wrongQuestionsCount">Bekijk de {{wrongQuestionsCount}} fout beantwoorde vragen nog even goed en ga dan verder.</span>
            <span v-else>Goed gedaan!</span>

            <span v-if="chapter.test.nextChapter">Je kunt nu doorgaan met de volgende module <i>"{{chapter.test.nextChapter.title}}"</i>.</span>
        </span>
    </p>
</template>

<script>
export default {
    name: 'ChapterFinishedMessage',

    props: {
        chapter: Object,
    },

    computed: {
        questionsCount() {
            let count = 0;

            this.chapter.slides.forEach((slide) => {
                count += slide.questions.length;
            });

            return count;
        },

        correctQuestionsCount() {
            let count = 0;

            this.chapter.slides.forEach((slide) => {
                slide.questions.forEach((question) => {
                    if (question.givenAnswer === question.correctAnswer) {
                        count++;
                    }
                });
            });

            return count;
        },

        correctQuestionsPercentage() {
            return Math.round(this.correctQuestionsCount / this.questionsCount * 100);
        },

        wrongQuestionsCount() {
            return this.questionsCount - this.correctQuestionsCount;
        }
    }
};
</script>

<style scoped>
    p.well {
        margin-bottom: 0;
    }
    p.well span {
        font-size: 16px;
        display: inline;
        padding-right: 4px;
    }
</style>