<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="6" xl="6">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <v-card-text class="px-12">
                            <v-row align="center" justify="start" class="mt-6">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-volume-high</v-icon>
                                    Zet je geluid aan! Kan dat niet via de luidsprekers? Gebruik dan een hoofdtelefoon. In de online training komen veel video’s voor waarin informatie wordt verteld.
                                </p>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-6">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-clock-outline</v-icon>
                                    De totale duur van de online training is ongeveer 25 minuten inclusief de vragen.
                                </p>
                            </v-row>
<!--                            <v-row align="center" justify="start" class="mt-6">-->
<!--                                <p class="icon-left pr-20">-->
<!--                                    <v-icon>mdi-subtitles-outline</v-icon>-->
<!--                                    Wil je gebruik maken van Nederlandse ondertiteling? Dat kan! Klik in de videospeler rechts onderaan op het icoontje met CC er in en klik op Nederlands. Nu zijn de video’s te zien met ondertiteling.-->
<!--                                </p>-->
<!--                            </v-row>-->
                        </v-card-text>
                        <v-card-actions class="mt-4">
                            <v-btn x-large text block color="primary" :to="{ name: 'chapters' }">
                                Verder <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';

export default {
    components: {
        HeaderImage,
    },
};
</script>
