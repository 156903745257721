import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#004c98',
          // primary: '#144173',
          secondary: '#e3001b',
          accent: '#ef8014',
          // error: '#b71c1c',
        },
        dark: {
          // primary: colors.purple,
          // secondary: '#144173',
        },
      },
    },
});
