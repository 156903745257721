import { render, staticRenderFns } from "./ChapterCard.vue?vue&type=template&id=6002d5f8&scoped=true&"
import script from "./ChapterCard.vue?vue&type=script&lang=js&"
export * from "./ChapterCard.vue?vue&type=script&lang=js&"
import style0 from "./ChapterCard.vue?vue&type=style&index=0&id=6002d5f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6002d5f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VHover,VIcon,VImg})
