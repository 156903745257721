import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import AuthenticationManager from "./services/AuthenticationManager";
import { jsonapiModule } from 'jsonapi-vuex'

Vue.use(Vuex);

const api = axios.create({
    // baseURL: '//api.prodrive-elearning.defineweb.test/api',
    baseURL: '//api.prodrive-elearning.defineweb.nl/api',
    // baseURL: '//api.onlinetraining.prodriveacademy.nl/api',
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
});

api.interceptors.response.use(response => response, (error) => {
    if (401 === error.response.status) {
        AuthenticationManager.invalidateSession();
        window.location.replace(window.location.protocol + "//" + window.location.host + "/login");
    }
    return Promise.reject(error);
});

export default new Vuex.Store({
    debug: true,
    modules: {
        jv: jsonapiModule(api),
    },
    state: {
        organization: null,
        oauth: null,
        loggedInUser: null,
        test: {
            chapters: [],
            slides: [],
            questions: [],
            answers: [],
        },
    },
    mutations: {
        setOrganization(state, organization) {
            state.organization = organization;
        },
        setOauth(state, oauth) {
            state.oauth = oauth;
        },
        setLoggedInUser(state, user) {
            state.loggedInUser = user;
        },
        invalidateSession(state) {
            state.oauth = null;
            state.loggedInUser = null;
        },
        setTest(state, test) {
            state.test = test;
        },
        unloadUser(state) {
            state.loggedInUser = null;
        },
    },
    actions: {
        setOrganization(state, organization) {
            this.commit('setOrganization', organization);
        },
        setOAuth(state, oauth) {
            if (oauth && oauth.hasOwnProperty('access_token')) {
                api.defaults.headers.common['Authorization'] = `Bearer ${oauth.access_token}`;
            }
            this.commit('setOauth', oauth);
        },
        invalidateSession() {
            api.defaults.headers.common['Authorization'] = null;
            this.commit('invalidateSession');
        },
        setLoggedInUser(state, user) {
            this.commit('setLoggedInUser', user);
        },
        setTest(state, test) {
            this.commit('setTest', test);
        },
        unloadUser() {
            this.commit('unloadUser');
        }
    },
    getters: {
        oauth: state => {
            return state.oauth;
        },
        getChapterById: (state) => (id) => {
            return state.test.chapters.filter(chapter => chapter.id === id)[0];
        },
        getSlideById: (state) => (id) => {
            return state.test.slides.filter(slide => slide.id === id)[0];
        },
        getQuestionById: (state) => (id) => {
            return state.test.questions.filter(question => question.id === id)[0];
        },
    }
});
