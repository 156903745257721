<template>
    <div class="slide-progress-indicator d-flex justify-space-around">
        <div v-for="_slide in slides" :key="_slide.id" :class="`slide ${_slide.isFinished ? 'finished' : 'todo'} ${_slide === slide ? 'active' : ''}`">
            {{_slide.name}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SlideProgressIndicationText',

    props: {
        slide: Object,
    },

    computed: {
        slides() {
            return this.slide.chapter.slides;
        },
        slidesCount() {
            return this.slide.chapter.slides.length;
        },
        slideIndex() {
            return this.slide.chapter.slides.indexOf(this.slide) + 1;
        }
    }
};
</script>

<style scoped>
    .slide-progress-indicator {
        text-align: center;
        padding: 10px 0 14px;
    }
    .slide-progress-indicator > div {
        position: relative;
        font-size: 14px;
        font-weight: 400;
    }
    .slide-progress-indicator > div.todo {
    }
    .slide-progress-indicator > div.active {
        font-weight: 600;
        opacity: 0.9 !important;
        text-decoration: underline;
    }
    .slide-progress-indicator > div.finished {
        opacity: 0.5;
        /*padding-left: 8px;*/
    }
    @media screen and (max-width: 720px) {
        .slide-progress-indicator > div {
            font-size: 11px;
        }
    }
    /*.slide-progress-indicator > div.finished::before {*/
    /*    content: "\F12C";*/
    /*    font-family: "Material Design Icons";*/
    /*    font-size: 16px;*/
    /*    position: absolute;*/
    /*    left: -0px;*/
    /*    margin-top: -2px;*/
    /*    display: inline-block;*/
    /*    text-rendering: auto;*/
    /*    line-height: inherit;*/
    /*    -webkit-font-smoothing: antialiased;*/
    /*    -moz-osx-font-smoothing: grayscale;*/
    /*    text-decoration: inherit;*/
    /*    vertical-align: inherit;*/
    /*}*/
</style>