<template>
    <div class="text-center">
        <img class="header-image" src="/images/warmteservice-logo.png" />
    </div>
</template>

<script>
export default {
    name: 'HeaderImage',
};
</script>

<style scoped>
    img.header-image {
        width: 100%;
        max-width: 400px;
        padding: 20px 18px 0 18px;
    }
</style>