<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="11" md="10" lg="8" xl="6">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <SlideProgressIndicationText v-bind:slide="slide"/>
                        <div class="video-container">
                            <vimeo-player ref="player" :video-id="slide.videoId" :options="{'responsive':true}" @ended="onVideoEnd" @ready="onPlayerReady" :autoplay="true"></vimeo-player>
                        </div>
                        <v-card-actions>
                            <v-btn v-if="chapterWasFinishedBeforeHand && slide.chapter.hasQuestions" x-large text color="primary" v-on:click="navigateToChapterOverview">
                                <v-icon left>mdi-arrow-left-bold-circle</v-icon> Terug
                            </v-btn>
                            <v-btn v-else x-large text color="primary" v-on:click="navigateToChapters">
                                <v-icon left>mdi-arrow-left-bold-circle</v-icon> Terug
                            </v-btn>

                            <v-spacer />

                            <v-btn v-if="0 < slide.questions.length" v-on:click="navigateToQuestion" :disabled="!canGoNext && !slide.isFinished" color="primary" x-large text>
                                Naar de vragen <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                            <v-btn v-else-if="slide.next" v-on:click="navigateToNextSlide" :disabled="!canGoNext && !slide.isFinished" color="primary" x-large text>
                                Volgende video <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                            <v-btn v-else-if="slide.chapter.hasQuestions" v-on:click="navigateToChapterOverview(true)" :disabled="!canGoNext && !slide.isFinished" color="primary" x-large text>
                                Bekijk tussenscore <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                            <v-btn v-else-if="slide.chapter.next" v-on:click="navigateToNextChapter" :disabled="!canGoNext && !slide.isFinished" color="primary" x-large text>
                                Volgende module <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                            <v-btn v-else v-on:click="navigateToChapters" :disabled="!canGoNext && !slide.isFinished" color="primary" x-large text>
                                Naar overzicht <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';
import SlideProgressIndicationText from './components/SlideProgressIndicationText';
import TestService from './services/TestService';

export default {
    components: {
        HeaderImage,
        SlideProgressIndicationText,
    },

    computed: {
        slide () {
            return this.$store.getters.getSlideById(parseInt(this.$route.params.slideId));
        },

        player () {
            return this.$refs.player;
        },
    },

    watch: {
        slide() {
            this.initializePlyr();
        }
    },

    data: () => ({
        canGoNext: false,
        chapterWasFinishedBeforeHand: false,
    }),

    mounted() {
        if (this.slide.chapter.isFinished) {
            this.chapterWasFinishedBeforeHand = true;
        }

        this.initializePlyr();
    },

    methods: {
        initializePlyr () {
            this.canGoNext = false;

            this.player.update(this.slide.videoId);
        },

        onPlayerReady() {
            this.player.play();
        },

        onVideoEnd() {
            this.canGoNext = true;

            if (0 === this.slide.questions.length) {
                TestService.finishSlide(this.slide);
            }
        },

        navigateToChapterOverview (firstTime = false) {
            let slide = this.slide;

            if (firstTime) {
                // Redundancy
                TestService.finishSlide(slide);
            }

            this.$router.push({
                name: 'chapter',
                params: {
                    chapterId: slide.chapter.id,
                }
            });
        },

        navigateToQuestion () {
            let slide = this.slide,
                question;

            if (slide.nextQuestion) {
                question = slide.nextQuestion;
            } else {
                question = slide.questions[0];
            }

            // Go to first question
            this.$router.push({
                name: 'question',
                params: {
                    chapterId: slide.chapter.id,
                    slideId: slide.id,
                    questionId: question.id,
                }
            });
        },

        navigateToNextSlide () {
            let slide = this.slide;

            TestService.finishSlide(slide);

            this.$router.push({
                name: 'slide',
                params: {
                    chapterId: slide.chapter.id,
                    slideId: slide.next.id,
                }
            });
        },

        navigateToNextChapter () {
            let slide = this.slide;

            TestService.finishSlide(slide);

            this.$router.push({
                name: 'slide',
                params: {
                    chapterId: slide.chapter.next.id,
                    slideId: slide.chapter.next.slides[0].id,
                }
            });
        },

        navigateToChapters () {
            this.$router.push({
                name: 'chapters',
            });
        },
    }
};
</script>