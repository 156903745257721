import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import store from './store';
import AuthenticationManager from './services/AuthenticationManager';
import TestService from './services/TestService';
import UserService from './services/UserService';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueGtag from "vue-gtag";

// Routes
import Chapters from './Chapters'
import Chapter from './Chapter'
import Slide from './Slide'
import Question from './Question'
import Registration from './Registration'
import Login from './Login'
import Explanation from './Explanation'
import PrivacyPolicy from './PrivacyPolicy'
import NotFound404 from './NotFound404'

Vue.config.productionTip = false;

Vue.use(VueRouter);

const routes = [
    { name: 'chapters', path: '/', component: Chapters },
    { name: 'slide', path: '/:chapterId/slides/:slideId', component: Slide },
    { name: 'question', path: '/:chapterId/slides/:slideId/questions/:questionId', component: Question },
    { name: 'registration', path: '/registreren', component: Registration, meta: { unauthenticated: true, authenticated: false } },
    { name: 'login', path: '/login', component: Login, meta: { unauthenticated: true, authenticated: false } },
    { name: 'privacyPolicy', path: '/privacy-policy', component: PrivacyPolicy, meta: { authenticated: false } },
    { name: 'explanation', path: '/uitleg', component: Explanation },
    { name: 'chapter', path: '/:chapterId(\\d+)', component: Chapter },
    { name: 'notFound404', path: '*', component: NotFound404 },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticatedRoute = !to.meta.hasOwnProperty('authenticated') ? true : to.meta.authenticated;
    const isUnauthenticatedRoute = !to.meta.hasOwnProperty('unauthenticated') ? false : to.meta.unauthenticated;

    AuthenticationManager.isAuthenticated().then((isAuthenticated) => {
        if (isAuthenticated) {
            if (isUnauthenticatedRoute) {
                // Go to authentication route index
                return next({ name: 'chapters' });
            }

            if (!UserService.userIsLoaded()) {
                // Load user
                UserService.fetchLoggedInUser();
            }

            if (!TestService.isTestLoaded()) {
                TestService.loadTest().then(() => {
                    next();
                });
            } else {
                next();
            }
        } else {
            // Is not authenticated
            if (isAuthenticatedRoute) {
                // Go to authentication route
                return next({ name: 'registration' });
            } else {
                next();
            }
        }
    });
});

Vue.use(VueGtag, {
    config: {
        id: "UA-9216956-34",
        params: {
            anonymize_ip: true,
            anonymizeIp: true,
        },
    }
}, router);

Vue.use(vueVimeoPlayer);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
