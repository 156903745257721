<template>
    <div class="gauge">
        <v-img src="/images/gauge-bg.png" contain>
            <v-img src="/images/gauge-needle.png" class="needle" v-bind:class="scoreClass" contain/>
            <span class="score white--text" v-if="score">{{score}}%</span>
        </v-img>
    </div>
</template>

<script>
export default {
    name: 'Gauge',

    props: {
        score: Number,
    },

    computed: {
        scoreClass() {
            return `score-${Math.round(this.score / 10) * 10}`;
        }
    },
};
</script>

<style scoped>
    .score-0 {
        -ms-transform: rotateZ(224deg);
        -webkit-transform: rotateZ(224deg);
        -moz-transform: rotateZ(224deg);
        transform: rotateZ(224deg);
    }
    .score-10 {
        -ms-transform: rotateZ(251deg);
        -webkit-transform: rotateZ(251deg);
        -moz-transform: rotateZ(251deg);
        transform: rotateZ(251deg);
    }
    .score-20 {
        -ms-transform: rotateZ(278deg);
        -webkit-transform: rotateZ(278deg);
        -moz-transform: rotateZ(278deg);
        transform: rotateZ(278deg);
    }
    .score-30 {
        -ms-transform: rotateZ(305deg);
        -webkit-transform: rotateZ(305deg);
        -moz-transform: rotateZ(305deg);
        transform: rotateZ(305deg);
    }
    .score-40 {
        -ms-transform: rotateZ(332deg);
        -webkit-transform: rotateZ(332deg);
        -moz-transform: rotateZ(332deg);
        transform: rotateZ(332deg);
    }
    .score-50 {
        -ms-transform: rotateZ(359deg);
        -webkit-transform: rotateZ(359deg);
        -moz-transform: rotateZ(359deg);
        transform: rotateZ(359deg);
    }
    .score-60 {
        -ms-transform: rotateZ(386deg);
        -webkit-transform: rotateZ(386deg);
        -moz-transform: rotateZ(386deg);
        transform: rotateZ(386deg);
    }
    .score-70 {
        -ms-transform: rotateZ(413deg);
        -webkit-transform: rotateZ(413deg);
        -moz-transform: rotateZ(413deg);
        transform: rotateZ(413deg);
    }
    .score-80 {
        -ms-transform: rotateZ(440deg);
        -webkit-transform: rotateZ(440deg);
        -moz-transform: rotateZ(440deg);
        transform: rotateZ(440deg);
    }
    .score-90 {
        -ms-transform: rotateZ(467deg);
        -webkit-transform: rotateZ(467deg);
        -moz-transform: rotateZ(467deg);
        transform: rotateZ(467deg);
    }
    .score-100 {
        -ms-transform: rotateZ(494deg);
        -webkit-transform: rotateZ(494deg);
        -moz-transform: rotateZ(494deg);
        transform: rotateZ(494deg);
    }

    .score {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        width: 60px;
        height: 22px;
        margin: -11px 0 0 -28px;
        font-weight: 600;
        font-size: 20px;
    }
    @media (max-width: 800px) {
        .score {
            font-size: 16px;
        }
    }
</style>